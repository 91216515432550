$border-radius: 4px;
$main-color: #95C11F;
$main-color-hover: darken(#95C11F, 20%);
$main-font-color: #666666;
$main-font-color-hover: darken(#666666, 20%);
$main-font-color-disabled: lighten($main-font-color, 20%);
$main-border-color: #cccccc;
$script-danger-color: #f71010;
$script-danger-color-hover: darken($script-danger-color, 20%);
$danger-color: #BB2B2B;
$danger-color-hover: darken($danger-color, 20%);
$warning-color: #FFCE32;
$warning-color-hover: darken($warning-color, 20%);
$serious-warning-color: #EC662B;
$serious-warning-color-hover: darken($serious-warning-color, 20%);
$white: #fff;
$whiteDark: #F5F5F5;
$navyGrey: #eee;
$black: #12252F;
$disabledInputBg: rgba(239, 239, 239, 0.3);

$navigationHeight: 90px;
$xsNavigationHeight: 60px;
$sideNavWidth: 220px;
$sideNavCollapsedWidth: 60px;
$footerHeight: 140px;
$xsFooterHeight: 150px;
$adminTopPadding: 0;
$contentTopMargin: 60px;
$contentBottomMargin: 60px;
$gridColumnGap: 20px;
$searchSubmitButtonWidth: 135px;
$xsSearchSubmitButtonWidth: 79px;

// form
$horizontalLabelRightPadding: 20px;
$horizontalLabelWidth: 205px;
$leftAlignedLabelWidth: 140px;
$leftAlignedLabelWidth160: 160px;
$leftAlignedLabelWidth100: 100px;
$helpButtonLeftPosition: 700px;
$smHelpButtonLeftPosition: 700px;
$input-height: 41px;
$input-font-size: 14px;
$xsInputHeight: 30px;

// breakpoints
$md: 1420px;
$sm: 900px;
$xs: 640px;

// mixins
@mixin input-styles {
  border: 1px solid $main-border-color;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-size: $input-font-size;
  color: $main-font-color;
  font-family: 'Poppins', sans-serif;
}

@mixin input-styles-xs {
  border-radius: 3px;
  padding: 6px;
  font-size: 12px;
}
