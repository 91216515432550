.admin-dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;

  .admin-dashboard__item {
    width: 270px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 15px;

    &:hover {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }

    .dashboard_row_item-link {
      text-decoration: none;
      color: #78BF2C;
    }

    &:hover .admin-dashboard__item-header-container {
      background-color: $black;
    }

    .admin-dashboard__item-header-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 35px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background-color: $main-font-color;

      .admin-dashboard__item-header {
        color: $white;
        text-align: center;
        padding: 10px;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .dashboard_row_item-number-container {
      height: 70px;
      display: flex;
      font-size: 40px;
      font-weight: bold;
      text-decoration: none;
      align-items: flex-start;
      padding: 0 20px;
      justify-content: flex-start;
      flex-direction: row;

      .dashboard_row_item-number {
        &.dashboard_row_item-number--text-color-yellow {
          color: $warning-color;
        }

        &.dashboard_row_item-number--text-color-amber {
          color: $serious-warning-color;
        }

        &.dashboard_row_item-number--text-color-red {
          color: $script-danger-color;
        }
      }
    }
  }

  &.users {
     .admin-dashboard__item {
      width: 200px;
     }
  }

  &.contract_signature_metadata {
    .admin-dashboard__item.manual_notary {
      .admin-dashboard__item-header {
        font-size: 10px;
      }
    }
  }
}
