.notifications-reader {


  .list-loader {
    display: none;
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    float: right;
    padding-top: 0.1rem;
    position: relative;
    top: -0.2rem;
  }

  &.loading .list-loader {
    display: inline-block;
  }

  .list-loader-icon {
    height: 1.2rem;
    width: 1.4rem;
  }

  .notifications-list {
    max-height: calc(100vh - $navigationHeight - $footerHeight);
    overflow-y: auto;
    background-color: $white;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .notification-list-item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $main-border-color;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    &:first-child {
      margin-top: 1.5rem;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .notification-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
  }

  .notification-icon {
    position: relative;

    .fa {
      font-size: 1.5rem;
    }

    &:hover {
      cursor: pointer;

      .fa {
        color: $main-color-hover;
      }
    }
  }

  .notification-list-item.unread .notification-icon:before {
    content: '';
    display: block;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: $danger-color;
    position: absolute;
    top: -0.05rem;
    right: 0.1rem;
  }

  .notification-icon-border {
    border: 1px solid $main-border-color;
    border-radius: 50%;
    height: 2.25rem;
    width: 2.25rem;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .notification-content {

  }

  .notification-title {
    display: block;
    font-size: 0.8rem;
    font-weight: bolder;
    text-decoration: none;
    margin-bottom: 0.3rem;
    color: $main-color;

    &:hover {
      color: $main-color-hover;
      cursor: pointer;
    }
  }

  .notification-annotation {
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }

  .notification-date {
    color: $main-font-color-disabled;
    font-size: 0.8rem;
  }

  @media (max-width: $xs) {
    .notifications-list {
      max-height: 20rem;
    }
  }
}
