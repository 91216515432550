@import '../variables';

.loading-preview {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: $border-radius;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.loading-preview__inner-div {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-preview__icon {
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
