@import '../variables';

.nested-choice-select.nested-choice-select--is-disabled {
  .nested-choice-select__input {
    cursor: not-allowed;
    background: $navyGrey;
  }
}

.nested-choice-select__input {
  border-radius: $border-radius;
  border: 1px solid $main-border-color;
  background: $white;
  height: $input-height;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}

.nested-choice-select__input:hover {
  cursor: pointer;
}

.nested-choice-select__input-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
}

.nested-choice-select__input-caret-container {
  align-self: center;
  margin-left: 10px;
}

.nested-choice-select__input-caret {
  color: $main-color;
}

.nested-choice-select__input-caret:hover {
  color: $main-color-hover;
}

.nested-choice-select__choices-container {
  margin-bottom: 10px;
  border-radius: $border-radius;
  border: 1px solid $main-border-color;
  padding: 10px;
  background-color: $white;
  max-height: 300px;
  overflow: auto;
  margin-top: 10px;
}

.nested-choice-select__choice-header.nested-choice-select__choice-header--is-last-level.nested-choice-select__choice-header--is-selected {
  color: white;
  background-color: $main-color;
  border-radius: $border-radius;
  padding-left: 10px;
  padding-right: 10px;
}

.nested-choice-select__choice-header {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nested-choice-select__choice-header:hover {
  cursor: pointer;
}

.nested-choice-select__choice-caret-container {
  margin-right: 10px;
  align-self: center;
}

.nested-choice-select__choice-caret {
  color: $main-color;
}

.nested-choice-select__choice-caret:hover {
  color: $main-color-hover;
  cursor: pointer;
}

.nested-choice-select__choice-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

.nested-choice-select__choice-label:hover {
  cursor: pointer;
}

.nested-choice-select__choice-children {
  padding-left: 40px;
}

.nested-choice-select__choices-autocomplete-input {
  width: 100%;
}

.nested-choice-select__choices-autocomplete-result-items {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: $border-radius;
  border: 1px solid $main-border-color;
}

.nested-choice-select__choices-autocomplete-result-item-message {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  cursor: not-allowed;
}

.nested-choice-select__choices-autocomplete-result-item {
  border-bottom: 1px solid $main-border-color;
  padding: 10px;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
}

.nested-choice-select__choices-autocomplete-result-item:hover {
  color: white;
  background-color: $main-color;
}

.nested-choice-select__choices-autocomplete-result-item:last-child {
  border-bottom: unset;
}

@media only screen and (max-width: $xs) {

  .nested-choice-select__input {
    height: 30px;
    margin-bottom: 0;
    font-size: 12px;
  }

  .nested-choice-select__choices-container {
    padding: 4px;
    margin-bottom: 0;
  }

  .nested-choice-select__choice-header {
    padding-top: 4px;
    padding-bottom: 4px;
  }

}
