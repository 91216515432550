@import '../variables';

.modal-backdrop {
  z-index: 500;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.modal {
  display: none;
  z-index: 501;
  position: fixed;
  overflow: hidden;
  min-width: 768px;
  max-width: calc(100vw - 40px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  max-height: calc(100vh - 50px);
  border-radius: 10px;

  &--short {
    min-width: initial;
    width: 460px;
  }

  .tab-header {
    margin: 0 -20px 28px;
    min-height: 43px;

    &__items {
      padding: 0 10px;
    }
  }

  &--is-shown {
    display: block;
  }

  &__body {
    overflow: auto;
    max-height: calc(100vh - 200px);
    padding: 20px;
  }

  &__header {
    background: $main-color;
    color: $white;
    padding: 0 20px;
    position: relative;
  }

  &__footer {
    padding: 10px;
    border-top: 1px solid $main-border-color;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 300;
  }

  &__header-close-button {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    border: none;
    background: transparent;
    color: $white;
    cursor: pointer;
  }
}

.modal--xs {
  min-width: 340px;
}

.modal--sm {
  min-width: 576px;
}

.modal--lg {
  min-width: 992px;
}

.modal--xl {
  min-width: 1200px;
}

.modal--xxl {
  min-width: 1400px;
}

@media only screen and (max-width: $sm) {

  .modal {
    min-width: initial;
    width: 90%;
  }
}


// kvuli stylum onfido
@media only screen and (max-width: 479px) {

  .modal {

    .onfido-sdk-ui-Modal-inner {
      position: initial;
      height: calc(100vh - 100px);
      padding-top: 20px;
    }

    .onfido-sdk-ui-NavigationBar-navigation {
      display: none;
    }
  }
}
