@import "../variables";

.alert {
  text-align: center;
  padding: 10px 20px;
  color: $white;
  margin-bottom: 5px;

  &--rounded-corners {
    border-radius: $border-radius;
  }

  &--success {
    background: $main-color;
  }

  &--danger {
    background: $danger-color;
  }

  &--warning {
    background: $warning-color;
    color: $main-font-color;

    a {
      color: $main-font-color;

      &:hover {
        color: $main-font-color-hover;
      }
    }
  }

  &--info {
    background: $navyGrey;
    color: $main-font-color;

    a {
      color: $main-font-color;

      &:hover {
        color: $main-font-color-hover;
      }
    }
  }
}
