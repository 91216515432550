@import '../variables';

$marginsTop: 0,5,10,17,20,30,44,60;
$marginsBottom: 0,10,20,30;
$marginsRight: 0,5,10,20,30;
$marginsLeft: 0,5,10,60,120;
$smMarginsTop: 20;
$smMarginsRight: 0;

@each $margin in $marginsTop {
  .mt-#{$margin} {
    margin-top: #{$margin}#{'px'};
  }
}

@each $margin in $marginsBottom {
  .mb-#{$margin} {
    margin-bottom: #{$margin}#{'px'};
  }
}

@each $margin in $marginsRight {
  .mr-#{$margin} {
    margin-right: #{$margin}#{'px'};
  }
}

@each $margin in $marginsLeft {
  .ml-#{$margin} {
    margin-left: #{$margin}#{'px'};
  }
}

.upside-down {
  transform: rotateX(180deg);
}

.no-decoration {
  text-decoration: none;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-40 {
  padding-bottom: 40px;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.color-green {
  color: $main-color;
}

.color-red {
  color: $danger-color;
}

.script-color-red {
  background-color: $black;
  color: $script-danger-color;
  padding: 0 6px;
  border-radius: 4px;
}

.script-color-green {
  background-color: $black;
  color: $main-color;
  padding: 6px;
  border-radius: 4px;
}

.script-color-yellow {
  background-color: $black;
  color: $warning-color;
  padding: 6px;
  border-radius: 4px;
}

.script-color-amber {
  color: $serious-warning-color;
  background-color: $black;
  padding: 6px;
  border-radius: 4px;
}

.w-75 {
  width: 75%;
}

.w-100 {
 width: 100%;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.flex-col {
  flex-direction: column;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.text-center {
  text-align: center!important;
}

.text-left {
  text-align: left;
}

.green-text {
  color: $main-color;
}

.text-grey {
  color: $main-font-color-disabled;
}

.stretch-vertically {
  min-height: calc(100vh - ($navigationHeight + $footerHeight));
}

.rounded {
  border-radius: 10px
}

.show-sm,
.show-xs {
  display: none;
}

.my-17 {
  margin-top: 17px;
  margin-bottom: 17px;
}

.m-0-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.fs-14px {
  font-size: 14px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-wrap {
  white-space: nowrap;
}

.no-no-wrap {
  white-space: initial;

  label {
    white-space: initial;
  }
}

.italic {
  font-style: italic;
}

.bold-label {

  label {
    font-weight: bold;
  }
}

.label-height-20px {

  label {
    min-height: 20px;
  }
}

.modal-with-help-text {

  .form__input-help-text {
    max-width: 625px;
  }
}

.make-space-for-help-icon {
  max-width: calc(100% - 34px);
}

.pl-2 {
  padding-left: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

@media only screen and (max-width: $md) {

  .flex-col-md {
    flex-direction: column;
  }
}

@media only screen and (max-width: $sm) {

  .hide-sm {
    display: none;
  }

  .w-100-sm {
    width: 100%;
  }

  .show-sm {
    display: initial;
  }

  .align-sm-normal {
    align-items: normal;
  }

  @each $margin in $smMarginsTop {
    .mt-sm-#{$margin} {
      margin-top: #{$margin}#{'px'};
    }
  }

  @each $margin in $smMarginsRight {
    .mr-sm-#{$margin} {
      margin-right: #{$margin}#{'px'};
    }
  }
}

@media only screen and (max-width: $xs) {

  .hide-xs {
    display: none;
  }

  .show-xs {
    display: initial;

    &--flex {
      display: flex;
    }
  }

  .mt-xs-0 {
    margin-top: 0;
  }

  .mb-xs-10 {
    margin-bottom: 10px;
  }

  .mt-xs-10 {
    margin-top: 10px;
  }

  .rounded {
    border-radius: 0;
  }

  .stretch-vertically {
    min-height: calc(100vh - ($xsNavigationHeight + $footerHeight));
  }

  .order-2-xs {
    order: 2;
  }

  .justify-initial-xs {
    justify-content: initial;
  }
}

.height-12px {
  height: 12px;
}

.p-0 {
  padding: 0;
}

.max-w-screen-md {
    max-width: 768px;
}

.max-w-screen-lg {
    max-width: 1024px;
}

.max-w-screen-xl {
    max-width: 1280px;
}

.max-w-screen-2xl {
    max-width: 1536px;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 0.25rem;
}

.p-3 {
  padding: 0.75rem;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.border-main {
  border: 1px solid $main-border-color;
}

.rounded {
  border-radius: $border-radius;
}

.justify-end {
  justify-content: flex-end;
}

.font-semibold {
    font-weight: 600;
}

.w-\[8\.5rem\] {
    width: 8.5rem;
}

.flex-grow {
    flex-grow: 1;
}

.w-full {
    width: 100%;
}

.max-w-\[20rem\] {
    max-width: 20rem;
}

.mt-\[2\.5rem\] {
    margin-top: 2.5rem;
}

.items-center {
    align-items: center;
}

.h-5 {
  height: 1.25rem;
}

.w-5 {
  width: 1.25rem;
}

.h-full {
  height: 100%;
}

.\!text-\[13px\] {
  font-size: 13px!important;
}

.\!text-\[12px\] {
  font-size: 12px!important;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.\!flex-nowrap {
  flex-wrap: nowrap!important;
}

.h-\[1\.5rem\] {
    height: 1.5rem;
}

.w-\[1\.5rem\] {
    width: 1.5rem;
}
