.risk-report-approval-workflow {
  .risk-report-approval-workflow__header {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .risk-report-approval-workflow__items-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .risk-report-approval-workflow__item-container {
      border: 1px solid $main-border-color;
      border-radius: $border-radius;
      margin-right: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:last-child {
        margin-right: unset;
      }

      .risk-report-approval-workflow__item-icon-container {
        text-align: center;
        margin-bottom: 20px;

        .risk-report-approval-workflow__item-icon {
          font-size: 30px;
        }
      }

      .risk-report-approval-workflow__item-title {
        text-align: center;
      }

      .risk-report-approval-workflow__item-actions {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .risk-report-approval-workflow__item-action {
          margin-right: 10px;

          &:last-child {
            margin-right: unset;
          }
        }
      }

      .risk-report-approval-workflow__item-annotation {
        margin-top: 20px;
        color: $white;
        text-align: center;

        a {
          color: $white;
        }
      }

      &.risk-report-approval-workflow__item-container--approved {
        border-color: $main-color;
        background-color: $main-color;

        .risk-report-approval-workflow__item-icon-container {
          .risk-report-approval-workflow__item-icon {
            color: $white;
          }
        }

        .risk-report-approval-workflow__item-title {
          color: $white;
        }
      }

      &.risk-report-approval-workflow__item-container--disapproved {
        border-color: $danger-color;
        background-color: $danger-color;

        .risk-report-approval-workflow__item-icon-container {
          .risk-report-approval-workflow__item-icon {
            color: $white;
          }
        }

        .risk-report-approval-workflow__item-title {
          color: $white;
        }
      }
    }
  }
}
