.identity-check {
  .identity-check__loader-image-container {
    text-align: center;

    .identity-check__loader-image {
      height: 75px;
    }
  }

  .identity-check__loader-text {
    text-align: center;
  }

  .identity-check__completed-icon-container {
    text-align: center;

    .identity-check__completed-icon {
      color: $main-color;
      font-size: 75px;
    }
  }

  .identity-check__completed-text {
    text-align: center;
  }

  .identity-check__onfido-mount {
    .onfido-sdk-ui-FaceVideo-introCopy {
      .onfido-sdk-ui-FaceVideo-introBullets {
        .onfido-sdk-ui-FaceVideo-introBullet {
          .onfido-sdk-ui-FaceVideo-introIcon {
            &.onfido-sdk-ui-FaceVideo-twoActionsIcon {
              background-image: url('~public/files/onfido-camera.svg');
            }

            &.onfido-sdk-ui-FaceVideo-speakOutLoudIcon {
              background-image: url('~public/files/onfido-microphone.svg');
            }
          }
        }
      }
    }

    .onfido-sdk-ui-QRCode-qrCodeHelp {
      .onfido-sdk-ui-QRCode-qrCodeHelpIcon {
        background-image: url('~public/files/onfido-question-mark.svg');
      }
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceLink-viewOptionsGroup {
      .onfido-sdk-ui-crossDevice-CrossDeviceLink-viewOption {
        &.onfido-sdk-ui-crossDevice-CrossDeviceLink-smsLinkOption {
          &:before {
            background-image: url('~public/files/onfido-message-bubble.svg');
          }
        }

        &.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyLinkOption {
          &:before {
            background-image: url('~public/files/onfido-chain.svg');
          }
        }

        &.onfido-sdk-ui-crossDevice-CrossDeviceLink-qrCodeLinkOption {
          &:before {
            background-image: url('~public/files/onfido-qr-code.svg');
          }
        }
      }
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer {
      &.onfido-sdk-ui-crossDevice-CrossDeviceLink-copySuccess {
        .onfido-sdk-ui-crossDevice-CrossDeviceLink-actionContainer {
          &:before {
            background-image: url('~public/files/onfido-check.svg');
          }
        }
      }
    }

    .onfido-sdk-ui-Theme-link, .onfido-sdk-ui-Theme-textButton {
      &:hover {
        text-decoration: underline;
      }
    }

    .ods-button {
      &:focus {
        box-shadow: unset;
      }
    }

    .onfido-sdk-ui-Welcome-content {
      .onfido-sdk-ui-Welcome-instructions {
        ol {
          text-align: left;
        }
      }
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadList {
      .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem {
        .onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon {
          background-image: url('~public/files/onfido-check-circle.svg');
        }
      }
    }
  }
}
