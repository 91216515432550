@import "../variables";

.footer {
  background-color: $white;
  border-top: 1px solid $navyGrey;

  a {
    white-space: nowrap;
  }

  &__content {
    text-align: center;
    padding: 40px 0 13px;
    flex: 1;
  }

  &__paragraph {
    padding-bottom: 40px;

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  &__copyright {
    color: $main-border-color;
    font-size: 14px;
    line-height: 1.2;
  }

  &__logo {
    width: 100px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $sm) {

  .footer {

    &__paragraph {
      padding-bottom: 20px;
    }

    &__copyright {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: $xs) {

  .footer {

    &__logo {
      margin: 20px 0 0;
    }

    &__content {
      padding: 10px 0;
    }
  }
}
