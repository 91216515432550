@charset "utf-8";

@import "variables";

:root {
  --main-border-color: #{$main-border-color};
  --border-radius: #{$border-radius};
  --main-color: #{$main-color};
}


// import components here 👇
@import "components/font";
@import "components/grid";
@import "components/loading-preview";
@import "components/select";
@import "components/modal";
@import "components/datagrid";
@import "components/dialog";
@import "components/footer";
@import "components/form";
@import "components/file-upload";
@import "components/navigation";
@import "components/ui";
@import "components/side-nav";
@import "components/alert";
@import "components/debug-menu";
@import "components/tabs";
@import "components/dropdown";
@import "components/steps";
@import "components/content";
@import "components/text";
@import "components/nested-choice-select";
@import "components/signatures-table";
@import "components/documentation";
@import "components/about-us";
@import "components/identity-check";
@import "components/input-group";
@import "components/contract-rs-fwd-bank-accounts";
@import "components/risk-report-approval-workflow";
@import "components/simple-table";
@import "components/navigation-accesses";
@import "components/risk-report-note-list";
@import "components/admin-dashboard";
@import "components/persisted-event-filter-form";
@import "components/application-metadata-identity-check-reports";
@import "components/application-deposit-bank-account-ownership-document";
@import "components/application-persons-company-owners-documents";
@import "components/application-persons-authorised-persons-to-act-for-company-power-fo-attorney-documents";
@import "components/application-trade-register-document";
@import "components/diff-result";
@import "components/risk-report-utils";
@import "components/notifications-reader";
@import "components/questionnaire-version-2";
@import "components/form-help";

// must be last ☝
@import "components/modificators";
