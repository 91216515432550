@import '../variables';


.diff-result {

  &__selects {
    max-width: 903px;
    flex: 1;
    display: flex;
    gap: 20px;
  }

  &__select {
    flex: 1;
  }

  &__submit {
    display: flex;
    align-items: center;
    margin: 7px 0 0 10px;
  }

  &__node {
    max-width: 903px;
  }

  &__node-name {
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0 0;
    padding-top: 10px;
    position: relative;
    border-top: 2px solid $main-border-color;
  }

  &__node-toggle-btn {
    padding: 2px 9px;
    border-radius: 50%;
    line-height: 26px;
  }

  &__node-toggle-btn-icon {
    font-size: 13px;
  }

  &__simple-diffs-collection {
    margin-bottom: 10px;
  }

  &__simple-diff {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  &__simple-diff-name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--mobile {
      display: none;
    }
  }

  &__simple-diff-values {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }

  &__simple-diff-value-wrapper {
    max-width: calc(50% - 50px);
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__simple-diff-value {
    padding: 5px;
    font-size: 14px;
    border: 1px solid $main-border-color;
    border-radius: $border-radius;
    flex: 1;
    max-width: 100%;
    overflow: hidden;
  }

  &__simple-diff-label {
    display: none;
  }
}

@media only screen and (max-width: $md) {

  .diff-result {

    &__selects {
      gap: 20px;
      flex-direction: column;
    }

    &__simple-diff-values {
      gap: 20px;
    }

    &__submit {
      margin: 0;
    }
  }
}

@media only screen and (max-width: $sm) {

  .diff-result {

    &__selects {
      gap: 10px;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: $xs) {

  .diff-result {

    &__simple-diff-name {
      display: none;

      &--mobile {
        display: block
      }
    }

    &__simple-diff-values {
      flex-direction: column;
      gap: 10px;
    }

    &__simple-diff-value-wrapper {
      max-width: 100%;
      flex-direction: row;
    }

    &__simple-diff-label {
      display: block;
      padding: 10px 6px 10px 0;
    }
  }
}
