@import "../variables";

.debug-menu {
  position: fixed;
  top: 20px;
  right: 40px;
  z-index: 1000;
}

@media only screen and (max-width: $xs) {

  .debug-menu {
    top: 16px;
    right: 100px;

    &--admin {
      top: 0;
      right: 20px;
    }
  }
}
