.simple-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      th {
        background-color: $main-color;
        color: $white;
        text-align: left;
        border: 1px solid $main-color;
        padding: 10px;

        &:first-child {
          border-top-left-radius: $border-radius;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: left;
        border: 1px solid $main-border-color;
        border-top: unset;
        border-right: unset;
        padding: 10px;
        background-color: $white;

        &:last-child {
          border-right: 1px solid $main-border-color;
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: $border-radius;
          }

          &:last-child {
            border-bottom-right-radius: $border-radius;
          }
        }
      }
    }
  }

  &--without-thead {
    tbody {
      tr {
        td {
          border-top: 1px solid $main-border-color;

          &:first-child {
            border-top-left-radius: $border-radius;
          }

          &:last-child {
            border-top-right-radius: $border-radius;
          }
        }
      }
    }
  }
}
