@import "../variables";

.datagrid {
  &__table-actions-wrapper {
    border-radius: $border-radius;
    margin-bottom: 20px;
    background-color: $white;
    padding: 25px;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }

  &__table-action {
    all: unset;
    background-color: $main-color;
    border: 1px solid $main-color;
    color: $white;
    padding: 5px;
    border-radius: $border-radius;

    &:hover {
      cursor: pointer;
      background-color: $main-color-hover;
      border-color: $main-color-hover;
      color: $white;
    }
  }

  &__table-wrapper {
    border-radius: 0;
    padding: 0 25px 0;
    background: $white;
    overflow-x: auto;
    max-height: calc(100vh - 141px);
  }

  &__header {
    border-radius: 10px 10px 0 0;
    background: $main-color;
    color: $white;
    padding: 0 22px;
    height: 41px;
    line-height: 41px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  &__table-heading {
    color: $black;
  }

  &__cell {
    padding: 6px 4px;

    &--value {
      white-space: nowrap;

      &[data-datagrid-cell-label="sent_email.subject"] {
        white-space: initial;
        max-width: 300px;
      }

      .fa-square-check {
        color: $main-color;
        transform: scaleX(1.3);
        margin-left: 2px;
      }

      iframe {
        width: 100%;

      }
    }

    &[data-datagrid-cell-label="sent_email.content"] {
      padding: 0;
    }

    &--email {

      a {
        text-decoration: none;
      }
    }
  }

  &__cell:not(.datagrid__cell--header) {
    border: 1px solid $navyGrey;

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  &__table-message-cell {
    padding-top: 10px;
  }

  &__actions-container {
    display: inline-block;
  }

  &__item-action {
    text-decoration: none;
    padding: 0 6px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 25px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    transition: background-color .2s ease-in;

    &--edit, &--detail {
      color: $white;
      background: $main-color;

      &:hover {
        color: $white;
        background: $main-color-hover;
      }
    }

    &--delete {
      color: $white;
      background: $danger-color;

      &:hover {
        color: $white;
        background: $danger-color-hover;
      }
    }

    &--copy {
      color: $white;
      background-color: $main-font-color;

      &:hover {
        color: $white;
        background-color: $main-font-color-hover;
      }
    }
  }

  &__collapsed-actions-container {
    display: inline-block;
    //position: relative;
  }

  &__collapsed_actions-toggler {
    all: unset;
    background-color: $main-font-color;
    color: $white;
    width: 15px;
    border-radius: $border-radius;
    text-align: center;
    transition: background-color .2s ease-in;
    font-size: 14px;
    line-height: 25px;

    &:hover {
      background-color: $main-font-color-hover;
      cursor: pointer;
    }
  }

  &__collapsed_actions-dropdown {
    position: absolute;
    //right: 0;
    border: 1px solid $main-border-color;
    border-radius: $border-radius;
    background-color: $white;
    z-index: 100;
  }

  &__collapsed-item-action {
    display: block;
    border-bottom: 1px solid $main-border-color;
    text-decoration: none;
    color: $main-font-color;
    padding: 5px 10px;
    transition: color .2s ease-in, background-color .2s ease-in;

    &:hover {
      color: $white;
      background-color: $main-color;
      cursor: pointer;
    }

    &:last-child {
      border-bottom: unset;
    }
  }

  &__cell--header {
    border-bottom: 1px solid $main-border-color;
    padding-top: 25px;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    background-color: $white;
  }

  &__table-body-row {

    &:first-of-type {

      .datagrid__cell:not(.datagrid__cell--actions):not(&[data-datagrid-cell-label="sent_email.content"]) {
        padding: 10px 4px 6px;
      }
    }
  }

  &__header-cell-controls {
    padding-right: 4px;
    min-width: 30px;
  }

  &__header-cell {
    display: flex;
    align-items: center;
  }

  &__header-cell-label {
    padding-right: 5px;
    font-weight: 700;
    color: $black;
    font-size: 16px;
    white-space: nowrap;
  }

  &__header-cell-control {
    text-decoration: none;

    &--sort-active {
      display: none;
    }
  }

  &__table-pagination {
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    padding: 10px 0;
    background: $white;
    border-top: 1px solid $main-border-color;
  }

  &__pagination-selects {
    display: flex;
    flex-direction: row-reverse;
  }

  &__pagination-pages-list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  &__pagination-list-item {
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pagination-link {
    text-decoration: none;
    border: 1px solid $main-border-color;
    border-radius: 999px;
    text-align: center;
    min-width: 30px;
    max-height: 30px;
    line-height: 30px;
    color: $main-font-color;
    transition: color .2s ease-in, background-color .2s ease-in, border-color .2s ease-in;
    padding: 0 4px;
    box-sizing: border-box;

    &:hover,
    &--active {
      background-color: $main-color;
      color: $white;
      border-color: $main-color;
    }

    &--disabled {
      border: none;
      pointer-events: none;
    }
  }

  &__pagination-select-wrapper {
    margin-right: 20px;
  }

  &__pagination-select-label {
    margin-right: 4px;
  }

  &__pagination-select {
    border-radius: 5px;
    border-color: $main-border-color;
    color: $main-font-color;
    padding: 4px;
  }

  &__table-filter {
    padding: 20px 25px;
    background: $white;
    border-radius: 10px 10px 0 0;

    .form {
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap;

      &__input-element {
        order: 1;
        margin-bottom: 0;
        max-width: 500px;

        input {
          border-radius: 5px 0 0 5px;
          outline: none;
        }
      }

      .datagrid__filter-form-submit-row {
        order: 2;
        margin-right: 10px;
      }

      &__checkbox-element {
        order: 3;
        margin-top: 10px;
        flex-basis: 100%;
      }
    }
  }

  &__filter-form-submit-btn {
    border: none;
    background-color: $main-color;
    color: $white;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 700;
    border-radius: 0 5px 5px 0;
    transition: background-color .2s ease-in, color .2s ease-in;
    padding: 8px 30px;
    cursor: pointer;
    height: 41px;
    font-family: "Poppins", sans-serif;

    &:hover {
      background-color:  $main-color-hover;
    }
  }
}

div[data-datagrid-snippet="pagination"] {
  padding: 25px;
  background: $white;
}

@media only screen and (max-width: $md) {
  .datagrid  {

    &__header-cell-label {
      font-size: 14px;
    }

    &__cell {
      font-size: 14px;
    }

    &__pagination-list-item {
      padding: 0 2px;
    }

    &__pagination-select-wrapper {
      margin-right: 10px;
    }

    &__pagination-select-label {
      font-size: 14px;
    }

    &__pagination-select {
      padding: 2px;
      font-size: 14px;
    }

    &__pagination-link {
      width: 20px;
      max-height: 20px;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1060px) {
  .datagrid  {

    &__table-wrapper {
      padding: 10px 10px 0;
    }

    &__table-filter {
      padding: 10px;
    }

    &__table-pagination {
      flex-direction: column;
      justify-content: flex-end;
      align-items: start;
      padding: 10px;
    }

    &__pagination-selects {
      padding: 10px 0;
    }
  }

  div[data-datagrid-snippet="pagination"] {
    padding: 10px;
  }
}

@media only screen and (max-width: 730px) {
  .datagrid  {

    &__header-cell-label {
      font-size: 12px;
    }

    &__cell {
      font-size: 12px;
    }

    &__pagination-select-wrapper {
      margin-right: 6px;
    }

    &__pagination-select-label {
      font-size: 12px;
    }

    &__pagination-select {
      font-size: 12px;
    }

    &__pagination-link {
      width: 16px;
      max-height: 16px;
      line-height: 16px;
      font-size: 12px;
    }

    &__table-filter {

    }
  }
}

.datagrid-without-borders {

  .datagrid__cell--actions {
    //position: sticky;
    //right: 0;
    background-color: $white;
    padding: 0;
  }

  .datagrid__actions-container {
    padding: 6px 4px;
  }
}

@media only screen and (min-width: $sm) {
  .datagrid-without-borders {

    .datagrid__cell:not(.datagrid__cell--header) {
      border: none;
    }
  }
}

@media only screen and (max-width: $sm) {

  .datagrid-without-borders {

    .datagrid__actions-container {
      border-left: 1px solid $navyGrey;
    }
  }

  .datagrid {

    &__table-filter {

      .form {

        .datagrid__filter-form-submit-row {
          margin-right: 0;
        }

        &__input-element {
          max-width: calc(100% - ($searchSubmitButtonWidth + 10px));
        }
      }
    }
  }
}

@media only screen and (max-width: $xs) {
  .datagrid {

    &__table-filter {

      .form {

        &__input-element {
          margin-bottom: 0;
          max-width: calc(100% - ($xsSearchSubmitButtonWidth + 10px));
        }
      }
    }

    &__table-pagination {
      border-radius: 0;
    }

    &__pagination-selects {
      flex-direction: column;
      line-height: 20px;
    }

    &__pagination-pages-list {
      justify-content: space-between;
    }

    &__pagination-link {
      width: 20px;
      height: 20px;
      line-height: 20px;
      max-height: 20px;
      font-size: 14px;
    }

    &__pagination-list-item:not(:first-of-type):not(:last-of-type):not(.datagrid__pagination-list-item--active) {
      display: none;
    }

    &__filter-form-submit-btn {
      width: $xsSearchSubmitButtonWidth;
      font-size: 12px;
      line-height: 1.1px;
      padding: 4px 10px;
      height: 30px;

      &:hover {
        background-color:  $main-color-hover;
      }
    }

    &__item-action {
      width: initial;
      padding: 2px 6px;
    }
  }
}
