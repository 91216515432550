.navigation-accesses {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  background-color: $whiteDark;
  border-radius: $border-radius;
  padding: 10px;

  .navigation-access__icon-container {
    align-self: center;

    .navigation-access__icon {
      font-size: 30px;
    }
  }

  .navigation-access__text {
    align-self: center;
    font-size: 14px;

    a {
      color: $main-font-color;

      &:hover {
        color: $main-font-color-hover;
      }
    }
  }
}
