@import "../variables";

.tab-header {
  overflow: auto;
  white-space: nowrap;
  margin: 0 -40px 29px;

  &__items {
    border-bottom: 1px solid $main-border-color;
    padding: 0 40px;
  }

  &__item {
    all: unset;
    text-decoration: none;
    color: $main-font-color;
    background: $navyGrey;
    padding: 8px 20px;
    margin: 0 2px;
    border: 1px solid $main-border-color;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    display: inline-block;
    transition: background-color .2s ease-in;

    &--is-selected {
      background: $white;
      position: relative;
      bottom: -1px;
    }

    &:hover {
      color: $main-font-color;
      background: $white;
      cursor: pointer;
    }

    &--add {
      background: $main-color;
      color: $white;
      border-color: $main-color;

      .fa {
        color: $main-color;
        background: $white;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      &:hover {
        color: $white;
        background: $main-color-hover;
      }

      &:hover .fa {
        color: $main-color-hover;
        background: $white;
        border-color: $main-color-hover ;
      }
    }
  }

  &__item-text {

  }
}

@media only screen and (max-width: $sm) {

  .tab-header {

    &__item {
      padding: 6px 10px;

      &--add {

        .fa {
          width: 19px;
          height: 19px;
        }
      }
    }
  }
}
