@import '../variables';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: $input-font-size;
    line-height: 1.2;

    &.required {

      &:after {
        content: '*';
        padding-left: 2px;
      }
    }
  }

  &__input-help {
    position: relative;

    &--outside {

      .form__input-help-button {
        top: 0;
        right: -26px;
      }
    }

    &--next-to-text {

      .form__input-help-button {
        position: static;
        margin-left: 10px;
      }
    }

    &--phone {
      .form__input-help-button {
        position: static;
        margin-left: 0;
        margin-right: 10px;
      }

      .form__input-element {

        label {
          padding-right: 10px !important;
        }
      }

      .form__input-help-text {
        order: 3;
      }
    }

    .form__errors {
      padding: 4px 0;
      background: none;
      margin-bottom: 0;

      li {
        color: $danger-color;
        padding-bottom: 0;
        font-size: 12px;
      }
    }
  }

  &__input-btn-group {
    display: flex;

    input {
      flex-grow: 1;
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }

    button {
      flex-grow: 0;
      font-size: $input-font-size;
      border-bottom-left-radius: unset!important;
      border-top-left-radius: unset!important;
      border-bottom-right-radius: $border-radius!important;
      border-top-right-radius: $border-radius!important;
      padding-left: 15px;
      padding-right: 15px;
      i {
        font-size: $input-font-size;
      }
    }
  }

  &__input-help-button {
    position: absolute;
    right: 4px;
    top: 26px;
    cursor: pointer;
    font-size: 20px;
  }

  &__input-help-text {
    font-size: 12px;
    font-weight: 300;
    padding: 10px;
    color: $white;
    background: $main-font-color;
    border-radius: 4px;
    max-width: min(625px, 100%);
    min-width: min(100%, 625px);
    box-sizing: border-box;

    &--translate-to-left {
      margin-top: 20px;
      transform: translateX(calc(-100% + 80px));
    }
  }

  &__separator {
    border: none;
    border-top: 1px solid $main-border-color;
    margin: 19px 0;
  }

  &__section-header {
    font-weight: 700;
    border-bottom: 1px solid $main-border-color;
    margin: 30px 0 24px;
  }

  &__info {
    font-size: 14px;
  }

  &__input-element {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    input {
      @include input-styles;
      height: 41px;
    }

    textarea {
      @include input-styles;
      font-family: 'Poppins', sans-serif;

      &:disabled {
        resize: none;
      }
    }

    .form__errors {
      padding: 4px 0;
      background: none;
      margin-bottom: 0;

      li {
        color: $danger-color;
        padding-bottom: 0;
        font-size: 12px;
      }
    }

    .help-text {
      font-size: 13px;
    }
  }

  &__checkbox-element {
    display: flex;
    align-items: center;

    input {
      border: 1px solid $main-border-color;
      -moz-appearance: none;
      -webkit-appearance: none;
      width: 13px;
      min-width: 13px;
      height: 13px;
      position: relative;
      margin: 0;

      &:checked {

        &:after {
          content: '\2713';
          position: absolute;
          color: $main-font-color;
          z-index: 2;
          top: -4px;
          left: 1px;
        }
      }

      &:disabled {
        background: $navyGrey;
      }
    }

    label {
      padding-left: 8px;
      white-space: nowrap;
    }

    .form__errors {
      padding: 4px 0 4px 10px;
      background: none;
      margin-bottom: 0;

      li {
        color: $danger-color;
        padding-bottom: 0;
        font-size: 12px;
      }
    }
  }

  &__radio-element {
    label.required {
      &:after {
        content: none;
      }
    }
  }

  &__phone-element {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .select2 {
      width: 150px !important;
      max-width: 150px;
      margin-right: 10px;
    }

    input {
      flex: 1;
      max-width: calc(100% - 127px);
    }
  }

  &__button-element {
    display: flex;
    flex-direction: column;
    margin: 13px 0 10px;

    .btn {
      min-width: 260px;
    }

    &--full-width {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .btn {
        min-width: initial;
      }
    }

    &--stretch-button {
      .btn {
        width: 100%;
      }
    }
  }

  &__compound-element-wrapper {
    label {
      margin-top: 10px;
    }
  }

  &__compound-element {
    margin-bottom: 10px;

    .form__radios-element {
      .form__checkbox-element {
        margin-top: 3px;
      }
    }
  }

  &__question {

    .form__compound-element > label,
    .form__input-element > label {
      margin-bottom: 25px;
      display: block;
    }
  }

  &__text {
    padding-bottom: 13px;
    font-size: $input-font-size;
  }

  &__errors {
    margin-top: 0;
    padding: 20px 10px 0;
    background: $danger-color;
    list-style: none;
    border-radius: 5px;

    li {
      color: $white;
      line-height: 1;
      padding-bottom: 20px;
    }
  }

  &__list {
    ul {
      margin: 0 0 0 10px;
      font-size: 14px;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;

    > label {
      font-weight: 700;
      min-width: 175px;
      padding-right: $horizontalLabelRightPadding;
    }
  }

  &__address-inputs {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    > div {
      padding-right: 10px;
    }
  }

  &__address-street {
    flex: 1;
  }

  &__address-country {
    min-width: 150px;
  }

  &__horizontal-labels {

    .form__input-element {
      flex-direction: row;
      align-items: center;
      position: relative;
      flex-wrap: wrap;

      label {
        font-weight: 700;
        min-width: $horizontalLabelWidth;
        padding-right: $horizontalLabelRightPadding;
        text-align: right;
      }

      input {
        min-width: calc(100% - ($horizontalLabelWidth + $horizontalLabelRightPadding));
      }

      .form__errors {
        box-sizing: border-box;
        top: 40px;
        z-index: 1;
        width: 100%;
        padding-left: calc($horizontalLabelWidth + $horizontalLabelRightPadding);

        li {
          background: $white;
        }
      }
    }

    .select2-container {
      flex: 1;
    }

    &--left-aligned {

      .form__input-element {

        label {
          font-weight: 300;
          min-width: $leftAlignedLabelWidth;
          text-align: left;
        }

        input {
          min-width: calc(100% - ($leftAlignedLabelWidth + $horizontalLabelRightPadding));
        }

        textarea {
          flex: 1;
        }

        .form__errors {
          padding-left: calc($leftAlignedLabelWidth + $horizontalLabelRightPadding);
        }
      }

      .form__phone-element {

        .form__errors {
          padding-left: 0;
        }
      }

      .form__compound-element {
        display: flex;
        margin-bottom: 0;

        > div {
          flex: 1;
        }

        > label {
          min-width: 376px;
        }

        .form__radios-element {
          display: flex;
        }

        .form__radio-element {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
          min-width: 100px;

          input {
            margin-right: 14px;
          }
        }
      }
    }

    &--no-fix-width {
      .form__input-element {

        label {
          min-width: initial;
          white-space: nowrap;
        }

        input {
          min-width: initial;
          flex: 1;
        }

        .form__errors {
          padding-left: 0;
        }
      }
    }

    &--width-100 {

      .form__input-element {

        label {
          min-width: $leftAlignedLabelWidth100;
        }

        input {
          min-width: calc(100% - ($leftAlignedLabelWidth100 - $horizontalLabelRightPadding));
        }

        .form__errors {
          padding-left: calc($leftAlignedLabelWidth100 + $horizontalLabelRightPadding);
        }
      }
    }

    &--width-160 {
      .form__input-element {

        label {
          min-width: $leftAlignedLabelWidth160;
        }

        input {
          min-width: calc(100% - ($leftAlignedLabelWidth160 + $horizontalLabelRightPadding));
        }

        .form__errors {
          padding-left: calc($leftAlignedLabelWidth160 + $horizontalLabelRightPadding);
        }
      }
    }

    &--width-180 {
      .form__input-element {

        label {
          min-width: 180px;
        }
      }
    }

    &--bold {
      .form__input-element {

        label {
          font-weight: 700;
        }

        input {
          min-width: initial;
          flex: 1;
        }
      }
    }
  }

  &__radios-top-label {

    .form__compound-element {

      > label {
        font-weight: bold;
      }
    }

    .form__radios-element {
      display: flex;
      align-items: end;

      .form__radio-element:first-of-type {
        input {
          margin-left: 0;
        }
      }
    }
  }

  &__multiple-checkbox-rows {
    .form__checkbox-element {
      margin-top: 5px;

      label {
        white-space: normal;
      }
    }

    .form__errors {

      padding: 4px 0;
      background: none;
      margin-bottom: 0;

      li {
        color: $danger-color;
        padding-bottom: 0;
        font-size: 12px;
      }
    }
  }

  .form__group-row {
    display: flex;
    gap: 20px;

    .form__compound-element {
      margin-bottom: 10px;
      border: 1px solid #CCCCCC;
      padding: 0 10px 10px 10px;
      border-radius: 4px;

      > label {
        font-weight: bold;
      }
    }

    .form__group-col {
      > .form__checkbox-element {
        margin-bottom: 10px;
        border: 1px solid #CCCCCC;
        padding: 10px;
        border-radius: 4px;
      }
    }

    .form__input-element {
      label {
        font-weight: bold;
      }
    }
  }

  .nested-choice-select__choices-container {
    position: absolute;
    margin-top: 5px;
    z-index: 10;
  }
}

@media only screen and (max-width: $sm) {

  .form {

    &__input-help-text {
      max-width: calc(100vw - 80px);
      min-width: initial;
    }

    &__horizontal-labels {

      &--compound {
        .form__compound-element {
          > label {
            min-width: 320px;
          }
        }
      }

      &--width-180-sm {

        .form__input-element {

          label {
            min-width: 180px;
          }
        }
      }

      &--no-wrap-md {

        .form__input-element {

          input,
          .select2 {
            max-width: initial;
          }
        }
      }
    }


    &__input-help {
      &--outside {

        .form__input-element {
          max-width: calc(100% - 26px);
        }

        .form__input-help-button {
          right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .form {

    label {
      line-height: 1;
    }

    &__errors {
      padding: 10px 10px 0;

      li {
        padding-bottom: 10px;
      }
    }

    label {
      font-size: 12px;
      white-space: initial;
    }

    &__info {
      font-size: 12px;
    }

    &__button-element {

      .btn {
        min-width: initial;
      }
    }

    &__input-element {

      input {
        @include input-styles-xs;
        height: $xsInputHeight;
        max-height: $xsInputHeight;
      }

      textarea {
        @include input-styles-xs;
      }

      label {
        margin-bottom: 4px;
        padding-right: 24px;
        white-space: initial !important;
      }

      .form__errors {

        li {
          font-size: 10px;
        }
      }
    }

    &__checkbox-element {

      .form__errors {
        min-width: 100%;
        padding-left: 0;

        li {
          font-size: 10px;
        }
      }
    }

    &__horizontal-labels {

      .form__input-element {
        flex-direction: column;
        align-items: initial;

        label {
          text-align: left;
        }

        .form__errors {
          top: 44px;
          padding-left: 0 !important;
        }
      }

      .form__compound-element {
        flex-direction: column;

        > label {
          min-width: initial;
        }

        .form__radio-element {
          justify-content: left;
          min-width: initial;

          label {
            min-width: 60px;
          }
        }
      }
    }

    &__phone-element {

      input {
        max-width: calc(100% - 130px);
      }
    }

    &__question {

      .form__compound-element > label,
      .form__input-element > label {
        margin-bottom: 6px;
      }
    }

    &__list {

      ul {
        margin: 0 0 0 4px;
        font-size: 12px;
      }
    }

    &__address-inputs {
      flex-direction: column;
    }

    &__input-help-text {
      max-width: calc(100vw - $gridColumnGap * 2);
    }

    &__input-help-button {
      font-size: 14px;
    }

    &__input-help {
      &--outside {

        .form__input-element {
          max-width: 100%;
        }

        .form__input-help-button {
          top: -4px;
        }
      }

      &--phone {
        .form__input-element {
          order: 3;
        }

        .form__input-help-text {
          order: initial;
        }
      }
    }
  }
}
