@import '../variables';

.about-us {

  &__banner {
    position: relative;
    overflow: hidden;
    background: $white;

    img {
      max-width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-link {
    font-weight: 700;
    font-size: 20px;
    white-space: nowrap;
    padding: 8px 0;
    display: inline-block;

    i {
      padding-right: 12px;
    }
  }

  &__header-btn {
    border: 1px solid $main-border-color;
    border-bottom: none;
    border-top: none;
    padding: 10px 64px;
  }

  &__card {
    overflow: hidden;
    border-radius: 0;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .125);
    margin-top: 35px;
  }

  &__card-header {
    background: $main-color;
    color: $white;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    padding: 16px;
  }

  &__card-body {
    padding: 30px 50px 30px;
    box-sizing: border-box;
    min-height: 194px;
    font-size: 20px;

    &--smaller {
      min-height: 154px;
    }

    &--bigger {
      min-height: 474px;
      padding: 24px 30px 30px;

      p {
        padding-top: 6px;
      }
    }
  }

  &__card-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    height: 42px;
  }

  &__card-separator {
    width: 272px;
    margin: 20px auto;
  }

  &__card-links {
    width: 220px;
    min-width: 220px;
    margin: 0 auto;

    &--wider {
      width: 250px;
    }
  }

  &__card-link {
    color: $main-font-color;
    font-weight: 700;
    font-size: 20px;
    display: block;
    white-space: nowrap;

    i {
      padding-right: 15px;
    }
  }

  &__card-address {
    padding: 14px 0 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.4;
  }

  &__inner {
    max-width: 710px;
    font-size: 14px;
    font-weight: 400;

    h2 {
      font-size: 24px;
    }
  }

  &__history {
    display: table;
    padding-bottom: 35px;
    overflow: hidden;

    &:last-of-type {
      padding-bottom: 0;

      .about-us__circle {

        &:after {
          content: none;
        }
      }
    }
  }

  &__year {
    color: $main-color;
    font-size: 20px;
    font-weight: 700;
    min-width: 62px;
    display: table-cell;
    vertical-align: top;
  }

  &__circle {
    position: relative;
    display: table-cell;
    width: 32px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      top: 32px;
      left: 16px;
      width: 1px;
      height: 300px;
      background: $main-border-color;
    }

    span {
      border-radius: 50%;
      overflow: hidden;
      width: 32px;
      height: 32px;
      background: $main-font-color;
      margin-right: 26px;
      display: inline-block;
    }
  }

  &__bubble {
    box-sizing: border-box;
    background: $navyGrey;
    border: 1px solid $main-border-color;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
    padding: 10px 7px 15px 12px;
    position: relative;
    display: table-cell;

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      left: -11px;
      top: 10px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 11px solid $main-border-color;
    }
  }

  &__form {
    width: 596px;
    box-sizing: border-box;
    margin: 30px auto 0;

    .form__horizontal-labels--left-aligned .form__input-element label {
      min-width: 100px;
    }

    .form__input-element {
      align-items: initial;
    }
  }
}

.care-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__number {
    font-size: 110%;
    margin-top: 10px;
    color: $black;
  }

  &__description {
    font-weight: 300;
    color: $main-font-color;
    text-align: center;
  }
}


@media only screen and (max-width: $md) {
  .about-us {

    &__banner {

      h1 {
        font-size: 30px;
      }
    }

    &__header-link {
      font-size: 16px;
    }

    &__card-header {
      font-size: 20px;
    }

    &__card-link {
      font-size: 16px;
    }

    &__card-address {
      font-size: 16px;
      padding: 8px 0 0 40px;
    }

    &__card-body {
      font-size: 16px;

      &--bigger {
        min-height: 358px;
      }
    }

    &__inner {

      h2 {
        font-size: 21px;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .about-us {

    &__form {
      width: auto;
    }

    &__header-btn {
      padding: 10px 32px;
    }

    &__header-link {
      font-size: 14px;
    }

    &__card-header {
      font-size: 16px;
      padding: 8px;
    }

    &__card-info {
      height: initial;
    }

    &__card-body,
    &__card-link,
    &__card-address {
      font-size: 14px;
    }

    &__card-body {
      min-height: initial;

      &--smaller,
      &--bigger {
        min-height: initial;
      }
    }

    &__inner {

      h2 {
        font-size: 18px;
      }
    }
  }
}


@media only screen and (max-width: $xs) {
  .about-us {

    &__header-btn {
      padding: 10px;
    }

    &__header-link {
      font-size: 12px;
      font-weight: 400;

      i {
        display: none;
      }
    }

    &__year {
      font-size: 16px;
      min-width: 50px;
    }

    &__circle {
      width: 26px;

      span {
        width: 26px;
        height: 26px;
      }

      &:after {
        top: 26px;
        left: 12px;
      }
    }

    &__form {
      width: 100%;
    }
  }
}
