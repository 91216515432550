.application-deposit-bank-account-ownership-document {
  border: 1px solid $main-border-color;
  border-radius: $border-radius;
  padding: 10px;


  .application-deposit-bank-account-ownership-document__title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .application-deposit-bank-account-ownership-document__btn {
  }
}
