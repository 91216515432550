@import "../variables";

.content {
  overflow: hidden;
  margin: $contentTopMargin 0 $contentBottomMargin;

  &--banner {
    max-width: 350px;
    min-width: 300px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 40px;
    margin-left: auto !important;
    margin-right: auto !important;

    .content__body {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding: 20px 20px 20px 40px;
      font-size: 14px;
    }

    .content__icon {
      max-width: 65px;
    }
  }

  &__dialog {
    width: 600px;
    max-width: 100%;
    margin: 60px auto 0;
  }

  &__separator {
    width: 208px;
    margin: 20px auto 13px;
    border-bottom: 1px solid $main-font-color;
  }

  &__center {
    width: 362px;
    margin: 0 auto;
  }

  &__header {
    background: $main-color;
    color: $white;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    line-height: 1;

    &--gray {
      background-color: $main-font-color;
      color: $white;
    }

    &--banner {
      padding: 24px 20px 14px;
      min-height: 98px;
      box-sizing: border-box;
    }

    h2 {
      font-size: 32px;
      line-height: 1;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
    }

    a {
      color: $white;
    }
  }

  &__list {
    padding: 0 0 0 1.5em;

  }

  &__body {
    background: $white;
    padding: 40px;
    box-sizing: border-box;

    &--two-banners {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 1rem;
    }

    &--admin {
      padding: 25px;
    }

    &--lesser-padding {
      padding: 20px 40px;
    }

    &--about {
      padding: 60px 80px;
    }
  }

  &__info {
    font-size: 14px;
    line-height: 1.5;
    margin: 60px auto;
    max-width: 400px;
  }

  &__buttons {
    width: 100%;
    max-width: 350px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .btn {
      text-align: center;
    }
  }

  &__link {
    text-decoration: none;
    display: block;
  }
}


.false-table {
  display: table;
  margin-bottom: 17px;
  font-size: 14px;

  &--no-margin {
    margin: 0;
  }

  &__cell {
    display: table-cell;
  }
}

@media only screen and (min-width: $md) {

  .content {

    &--stretch-vertically-lg {
      min-height: calc(100vh - ($footerHeight + $navigationHeight + $contentTopMargin + $contentBottomMargin));
      height: calc(100% - ($contentBottomMargin + $contentTopMargin));
      display: flex;
      flex-direction: column;

      div[data-datagrid-component],
      div[data-datagrid-snippet="table"] {
        flex: 1;
        display: flex;
        flex-direction: column;

        .datagrid__table-wrapper {
          flex: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .content {
    margin: 40px 0 20px;
  }
}

@media only screen and (max-width: $sm) {

  .content {
    margin: 20px 0;

    &__info {
      font-size: 12px;
      margin: 20px auto;
    }

    &__header {

      &--banner {
        min-height: 90px;
      }

      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }
    }

    &__body {
      background: $white;
      padding: 20px;

      &--two-banners {
        flex-direction: column;
        justify-content: initial;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .false-table {
    font-size: 12px;
  }

  .content {
    margin: 0;

    &__center {
      width: 100%;
    }

    &__header:not(.content__header--banner) {
      padding: 10px 20px;
      line-height: 1.2;
    }

    &__body {
      padding: 10px;
    }
  }

  .false-table {

    &__cell {
      display: block;
      padding-right: 0;
    }
  }
}
