@import "../variables";

.hidden-anchor {
  display: block;
  position: relative;
  top: -$navigationHeight;
  visibility: hidden;
}

.navigation-header {
  background: $white;
  position: fixed;
  height: calc($navigationHeight - 1px);
  display: flex;
  justify-content: center;
  width: 100vw;
  border-bottom: 1px solid $navyGrey;
  z-index: 400;
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  .locale-switcher {
    padding-left: 15px;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__dropdown-items {
    display: none;
    background: $white;
    user-select: none;
    padding-top: 4px;

    .navigation__link {
      color: $main-font-color;
      font-size: 16px;

      &:hover {
        color: $main-font-color-hover;
      }

      &--icon {
        display: flex;

        i {
          font-size: 22px;
          padding-right: 8px;
          width: 30px;
          box-sizing: border-box;
          color: $main-color;
        }
      }

      &:hover {
        color: $black;
      }
    }
  }

  &__dropdown-items-inner {
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    padding: 14px 11px 14px 0;
  }

  &__item {

    &--dropdown {
      position: relative;

      > .navigation__link {
        cursor: pointer;
        font-size: 16px;

        i {
          color: $main-color;
          padding-left: 10px;
        }
      }

      &-collapsed,
      &:hover {

        .navigation__dropdown-items {
          display: block;
          position: absolute;
          top: 30px;
          right: 0;
        }

        > .navigation__link {
          background: $main-color;
          color: $white;
          border-radius: 999px;
          transition: background-color .2s ease-in;

          i {
            color: $white;
          }
        }
      }
    }
  }

  &__logo {
    margin-right: 20px;
    padding-top: 20px;

    img {
      width: 120px;
    }
  }

  &__link {
    color: $black;
    padding: 6px 16px;
    font-size: 1rem;
    text-decoration: none;

    &--active {
      background: $main-color;
      color: $white;
      border-radius: 999px;
      transition: background-color .2s ease-in;

      &:hover {
        color: $white;
        background: $main-color-hover;
      }
    }

    &--email {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: $md) {
  .navigation {

    &__link {
      font-size: 18px;
    }

    &__logo {
      max-width: 150px;
    }
  }
}

@media only screen and (max-width: $sm) {

  .navigation {

    &__link {
      font-size: 16px;
      padding: 3px 8px;
    }

    &__logo {
      max-width: 100px;
    }

    &__item {

      &--dropdown {
        &-collapsed,
        &:hover {

          .navigation__dropdown-items {
            top: 27px;
          }
        }
      }
    }

    &__dropdown-items-inner {
      padding: 8px 0;
    }
  }
}

@media only screen and (max-width: $xs) {

  .navigation-header {
    height: calc($xsNavigationHeight - 1px);
  }

  .navigation {
    padding: 0 10px;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    max-width: 100vw;

    &__controls {
      display: flex;
      align-items: center;
    }

    &__dropdown-items {
      padding: 14px 11px;
    }

    &__link {
      font-size: 16px;
      padding: 4px 0;

      &--active {
        border-radius: 0;
        background: $white;
        color: $main-color;
      }

      &--icon {
        i {
          display: none;
        }
      }
    }

    &__logo {
      max-width: 100px;
    }

    &__toggler {

      i {
        font-size: 20px;
        color: $main-color;
      }
    }

    &__item {
      &--dropdown {
        & > .navigation__link i {
          display: none;
        }
      }
    }

    &__items {
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      top: $xsNavigationHeight;
      left: 0;
      right: 0;
      background: $white;
      border-bottom: 1px solid $navyGrey;
    }

    .locale-switcher {
      .navigation__link {
        padding: 4px;
      }

      .navigation__dropdown-items-inner {
        padding: 4px 0;

        .navigation__link {
          padding: 0 14px;
        }
      }
    }
  }
}

.navigation {
  .navigation__item.notifications {
    position: relative;
    margin-right: 2rem;

    .menu-icon {
      display: block;
      text-align: center;
      position: relative;

      &:hover {
        cursor: pointer;
        color: $main-color;
      }

      .fa {
        font-size: 1.25rem;
      }
    }

    .unread-count {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      background-color: $danger-color;
      color: #fff;
      font-size: 0.7rem;
      border-radius: 50%;
      display: inline-block;
      height: 1.1rem;
      width: 1.1rem;
      text-align: center;
      line-height: 1.2rem;
    }

    .submenu {
      position: absolute;
      right: 0;
      width: 16rem;
      border-radius: $border-radius;
      border: 1px solid $main-border-color;
      padding: 1rem;
      background-color: $white;
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;
      display: none;
    }

    .submenu-item {
      display: flex;
      flex-direction: row;
      justify-content: start;
      border-bottom: 1px solid $main-border-color;
      padding-bottom: 1rem;
      margin-bottom: 1rem;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .notification-title {
      display: block;
      font-size: 0.8rem;
      font-weight: bolder;
      text-decoration: none;
      margin-bottom: 0.3rem;
    }

    .notification-annotation {
      font-size: 0.8rem;
      margin-bottom: 0.3rem;
    }

    .notification-icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 1rem;
    }

    .notification-icon-border {
      border: 1px solid $main-border-color;
      border-radius: 50%;
      height: 2.25rem;
      width: 2.25rem;
      overflow: hidden;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .notification-icon {
      position: relative;

      .fa {
        font-size: 1.5rem;
      }
    }

    .notification-date {
      color: $main-font-color-disabled;
      font-size: 0.8rem;
    }

    .submenu-item.unread .notification-icon:before {
      content: '';
      display: block;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: $danger-color;
      position: absolute;
      top: -0.05rem;
      right: 0.1rem;
    }

    .submenu-link {
      font-size: 0.8rem;
      font-weight: bolder;
      text-decoration: none;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
