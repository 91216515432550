.persisted-event-filter-form {
  .persisted-event-filter-form__audit-entity-filters-container {
    .persisted-event-filter-form__audit-entity-filters-label-container {
      margin-bottom: 20px;

      label {
        font-weight: bold;
      }

      .persisted-event-filter-form__audit-entity-filters-add-btn {
        padding: 5px 10px;
        border-radius: 50%;
        margin-left: 10px;

        i.fa {
          font-size: 15px;
        }
      }
    }

    .persisted-event-filter-form__audit-entity-filters-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px;

      .persisted-event-filter-form__audit-entity-filters-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: $border-radius;
        border: 1px solid $main-border-color;

        .persisted-event-filter-form__audit-entity-filters-item-entity-class-container {
          width: 450px;
        }

        .persisted-event-filter-form__audit-entity-filters-item-entity-identifier-container {
          width: 75px;
        }

        .persisted-event-filter-form__audit-entity-filters-item-controls {
          align-self: end;
          padding-bottom: 12px;

          .persisted-event-filter-form__audit-entity-filters-item-control-remove-btn {
            padding: 5px 10px;
            border-radius: 50%;

            i.fa {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
