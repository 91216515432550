.application-trade-register-document {
  border: 1px solid $main-border-color;
  border-radius: $border-radius;
  padding: 10px;


  .application-trade-register-document__title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .application-trade-register-document__btn {
  }
}
