@import "../variables";
// Zde je mozne si podle potreby doplnit sirku sloupcu v procentech
// Oproti bootstrapu to jde obracene, desktop first. col-50 je pro vsechny, col-md-50 je jen pro rozliseni do $md ve variables

$cols: 10,11,19,20,25,30,33,34,35,37,40,45,47,50,55,60,66,70,75,100;
$mdCols: 35,40,45,50,60,65,70,100;
$smCols: 50,75,90,100;
$xsCols: 40,60,100;

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -#{$gridColumnGap} 0 -#{$gridColumnGap};
  width: calc(100% + 2 * $gridColumnGap);
}

@each $col in $cols {
  .col-#{$col} {
    padding: 0 $gridColumnGap;
    box-sizing: border-box;
    width: #{$col}#{'%'};
    max-width: #{$col}#{'%'};
  }
}

.col-3333 {
  padding: 0 $gridColumnGap;
  box-sizing: border-box;
  width: 33.33%;
}

@media only screen and (max-width: $md) {
  @each $col in $mdCols {
    .col-md-#{$col} {
      padding: 0 $gridColumnGap;
      box-sizing: border-box;
      width: #{$col}#{'%'};
      max-width: #{$col}#{'%'};
    }
  }
}

@media only screen and (max-width: $sm) {
  @each $col in $smCols {
    .col-sm-#{$col} {
      padding: 0 $gridColumnGap;
      box-sizing: border-box;
      width: #{$col}#{'%'};
      max-width: #{$col}#{'%'};
    }
  }
}

@media only screen and (max-width: $xs) {
  @each $col in $xsCols {
    .col-xs-#{$col} {
      padding: 0 $gridColumnGap;
      box-sizing: border-box;
      width: #{$col}#{'%'};
      max-width: #{$col}#{'%'};
    }
  }
}
