@import "../variables";

.signatures-table {
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;

  &__wrapper {
    overflow: auto;
  }

  td, th {
    border-top: 1px solid $main-border-color;
    padding: 20px 10px;

    i {
      font-size: 24px;
      vertical-align: inherit;
    }
  }

  th {
    text-align: left;
    font-weight: 700;
  }

  &__actions {
    padding: 16px 10px;

    .btn {
      font-size: 14px;
      line-height: 14px;
      padding: 4px 10px;
      display: inline-block;
      margin: 2px 0;
    }
  }
}
