@font-face {
  font-family: 'Poppins';
  src: url('../../font/Poppins-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../font/Poppins-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../font/Poppins-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../font/Poppins-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../font/Poppins-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
