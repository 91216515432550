@import '../variables';

.dialog-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1040px;
  padding: 80px 0;
  box-sizing: border-box;
}

.dialog {
  width: 533px;
  border-radius: 10px;
  overflow: hidden;

  &__header {
    background: $main-color;
    color: $white;
    padding: 5px 20px;
  }

  &__headings {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }

  &__content {
    background: $white;
    padding: 40px 60px 90px;
  }

  &__note {
    font-size: 14px;
    line-height: 21px;
  }

  &__footer {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 14px;
  }

  &__other-logins {
    display: flex;
    flex-direction: column;
  }

  &__info {
    display: flex;
    background: $whiteDark;
    padding: 9px 17px;
    border-radius: 5px;
  }

  &__info-icon {
    margin-right: 13px;
  }

  &__info-icon {
    margin-right: 13px;
  }
}

.info-box {
  background: $white;
  padding: 32px 37px;
  width: 382px;
  box-sizing: border-box;
  border-radius: 10px;

  &--lesser-paddings {
    padding: 22px 15px;
  }

  &__element {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 64px;
    min-width: 64px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  &__text {
    line-height: 1.4;
  }

  &__title {
    font-size: 20px;
  }

  &__paragraph,
  &__list {
    font-size: 14px;
  }

  &__list {
    padding-left: 28px;
  }
}

@media only screen and (max-width: $md) {

  .info-box {
    width: 350px;
  }

  .dialog {
    width: 480px;
  }
}

@media only screen and (max-width: $sm) {

  .dialog-wrapper {
    flex-direction: column-reverse;
    padding: 40px;
    align-items: center;
  }

  .dialog {

    &__content {
      padding: 20px;
    }
  }

  .info-box {
    margin-top: 40px;
  }
}

@media only screen and (max-width: $xs) {

  .dialog-wrapper {
    padding: 20px;
  }

  .info-box {
    width: 100%;
    margin-top: 20px;
    padding: 20px;

    &__element {
      margin-bottom: 0;
    }

    &__icon {
      min-width: 40px;
      max-width: 40px;
      margin-right: 10px;
    }
  }

  .dialog {
    width: 100%;

    &__headings {
      font-size: 16px;
    }
  }
}
