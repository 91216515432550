.risk-report-note-list {
  .risk-report-note-list__item {
    margin-bottom: 20px;
    border: 1px solid $main-border-color;
    border-radius: $border-radius;
    padding: 10px;

    .risk-report-note-list__item-content {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: unset;
    }
  }
}
