@import "../variables";

.contract-rs-fwd {

  &__bank-account {
    border: 1px solid $main-border-color;
    padding: 20px;
    border-radius: $border-radius;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  &__bank-account-controls {
    text-align: right;
    margin-top: 17px;
  }
}

@media only screen and (max-width: $sm) {

  .contract-rs-fwd {

    &__bank-account {

      .form__input-help-text {
        max-width: calc(100vw - 120px);
        min-width: calc(100vw - 120px);
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .contract-rs-fwd {

    &__bank-account {

      .form__input-help-text {
        max-width: calc(100vw - 80px);
        min-width: calc(100vw - 80px);
      }
    }

    &__bank-account-controls {
      margin-top: 15px;
    }
  }
}
