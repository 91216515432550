@import "../variables";

.steps {
  overflow: hidden;

  &__step {
    display: table;
    padding-bottom: 14px;

    //&.step-done {
    //  padding-bottom: 42px;
    //}

    &:last-of-type {
      padding-bottom: 0;

      .steps__step-number {

        &:after {
          content: none;
        }
      }
    }

    &:hover,
    &.step-done:hover {

      .steps__step-title {

        a {
          color: $main-font-color-hover;
        }
      }

      .steps__step-number {

        a  {
          background: $main-font-color-hover;
        }
      }
    }
  }

  &__step-number {
    display: table-cell;
    width: 32px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      display: block;
      top: 32px;
      left: 16px;
      width: 1px;
      height: 200px;
      background: $main-border-color;
    }

    span,
    a {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      color: $white;
      background: $main-font-color;
      overflow: hidden;
      font-size: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    i {
      font-size: 14px;
    }
  }

  &__step-title {
    padding-left: 14px;
    display: table-cell;
    color: $main-font-color;

    a {
      color: $main-font-color;
      text-decoration: none;
      transition: color .2s ease-in;
    }
  }

  .step-done {

    .steps__step-title {

      a {
        color: $main-border-color;
      }
    }

    .steps__step-number {

      span,
      a {
        text-decoration: none;
        background: $main-border-color;
        transition: background-color .3s ease-in;
      }
    }
  }

  .current-step {

    .steps__step-number span {
      background: $main-color;
    }
  }

  .step-invalid {

    .steps__step-title {
      color: $danger-color;

      span, a {
        color: $danger-color;
      }
    }

    &.step-linkable:hover {
      .steps__step-title {
        color: $danger-color-hover;

        span, a {
          color: $danger-color-hover;
        }
      }
    }
  }
}
