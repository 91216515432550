@import '../variables';

html {
  scroll-behavior: smooth;
}

body, div, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  background: $whiteDark;
  color: $main-font-color;
  font-family: 'Poppins', sans-serif;
}

a {
  color: $main-color;
  transition: color .2s ease-in;

  &:hover {
    color: $main-color-hover;
  }
}

.main {
  padding-top: $navigationHeight;

  &--admin {
    padding-top: $adminTopPadding;
    width: 100%;
  }
}

.admin-layout {
  display: flex;
  padding-left: $sideNavWidth;
  transition: padding .2s ease-in;
}

.container {
  max-width: 1720px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  overflow: hidden;

  &--admin {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;

    .content {
      margin: 20px 0;
    }
  }

  &--dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--footer {
    display: flex;
    align-items: center;
  }
}

.btn {
  border: none;
  text-decoration: none;
  color: inherit;
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400;
  border-radius: 999px;
  font-family: 'Poppins', sans-serif;
  transition: background-color .2s ease-in, color .2s ease-in;
  padding: 8px 30px;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;

  &--circle {
    border-radius: 50%;
  }

  i {
    font-size: 20px;
    vertical-align: middle;
  }

  &--lesser-padding {
    padding: 8px;
  }

  &--smaller {
    padding: 3px 20px;
  }

  &--icon-right {
    padding-right: 20px;
    white-space: nowrap;

    i {
      padding-left: 10px;
    }
  }

  &--icon-left {
    padding-left: 14px;
    white-space: nowrap;

    i {
      padding-right: 10px;
    }
  }

  &--back {
    padding-left: 20px;
  }

  &--next {
    padding-right: 20px;
  }

  &--link {
    color: $main-color;
    padding: 0;
    text-align: left;
    line-height: 1.1;

    &.loading {
      &:before {
        transform-origin: center;
        content: '';
        display: inline-block;
        width: 7.5px;
        height: 7.5px;
        border: 2px solid $main-color;
        border-top-color: $white;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    }
  }

  &--primary {
    background-color: $main-color;
    color: $white;

    &:hover {
      color: $white;
      background-color: $main-color-hover;
    }
  }

  &--danger {
    background-color: $danger-color;
    color: $white;

    &:hover {
      color: $white;
      background-color: $danger-color-hover;
    }
  }

  &--danger-outline {
    background-color: $white;
    border-color: $danger-color;
    color: $danger-color;

    &:hover {
      border-color: $danger-color-hover;
      color: $danger-color-hover;
    }
  }

  &--primary-outline {
    background-color: $white;
    border-color: $main-color;
    color: $main-color;

    &:hover {
      border-color: $main-color-hover;
      color: $main-color-hover;
    }
  }

  &--input {
    max-height: 43px;
    margin-left: 10px;
  }

  &--secondary {
    background-color: $main-font-color;
    color: $white;

    &:hover {
      color: $white;
      background-color: $main-font-color-hover;
    }
  }

  &--dialog-login {
    border: 1px solid $main-font-color;
    text-align: left;
    margin-bottom: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      color: $black;
    }

    img {
      width: 26px;
      height: 26px;
      margin-right: 70px;
      vertical-align: middle;
    }
  }

  &--icon-only {
    padding: 0;
    color: $main-color;

    i {
      font-size: 24px;
    }
  }

  &:disabled {
    color: $black;
    background: $navyGrey;
    pointer-events: none;
  }

  &--disabled {
    color: $main-font-color;
    pointer-events: none;
    background: $navyGrey;
    border-color: $main-border-color;
  }

  &__content {
    display: inline-block;
  }

  &:not(.btn--link).loading {
    opacity: 0.75;
    pointer-events: none;
    position: relative;

    .btn__content {
      visibility: hidden;
    }

    &:after {
      position: absolute;
      left: calc(50% - 7.5px);
      top:calc(50% - 7.5px);
      transform-origin: center;
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid $white;
      border-top-color: $main-color;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }

  &.opacity-loading {
    opacity: 0.75;
    pointer-events: none;
    cursor: not-allowed;
  }

  &--sm {
    padding: 6px 20px;

    &.btn--circle {
      padding: 6px;
    }
    i {
      font-size: 12px;
    }
  }

  &--xs {
    padding: 6px 12px;
    &.btn--circle {
      padding: 0;
      height: 34px;
      width: 34px;
      line-height: 12px;
    }
    i {
      font-size: 10px;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.separator {
  display: flex;
  align-items: center;
  margin: 20px 0;

  &__text {
    padding: 0 30px;
  }

  &__line {
    flex: 1;
    height: 1px;
    background-color: $main-font-color;

    &--grey {
      background-color: $main-border-color;
    }
  }
}

.locale-switcher {

  a {
    color: $black;
    text-decoration: none;
  }
}

.pdf-iframe-wrapper {
  iframe {
    width: 100%;
    max-height: calc(100vh - 260px);
  }
}

.two-buttons {
  .btn:first-of-type {
    margin-right: 20px;
  }
}

@media only screen and (max-width: $sm) {
  body {
    font-size: 14px;
  }

  .container {

    &--admin {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .round-green-icon {
    font-size: 70px;
  }

  .pdf-iframe-wrapper {
    padding-top: 20px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 18px;
    line-height: 18px;

    &--link {
      padding: 0;
    }

    &--smaller {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: $xs) {

  .admin-layout {
    padding-left: $sideNavCollapsedWidth;
  }

  body {
    font-size: 12px;
  }

  .main {
    padding-top: $xsNavigationHeight;

    &--admin {
      padding-top: 0;
    }
  }

  .btn {

    i {
      font-size: 14px;
    }

    &--back {
      padding-left: 10px;

      &:before {
        background-size: 20px;
      }
    }

    &--next {
      padding-right: 10px;

      &:before {
        background-size: 20px;
      }
    }

    &--dialog-login {
      margin-bottom: 8px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 20px;
        vertical-align: middle;
      }
    }

    &--input {
      max-height: 32px;
    }

    &--smaller {
      padding: 8px 10px;
    }

    &--icon-only {
      i {
        font-size: 24px;
      }
    }

    &--lesser-padding {
      padding: 6px;
    }
  }

  .container {
    padding-right: 0;
    padding-left: 0;

    &--footer {
      flex-direction: column;
    }

    &--admin {
      padding: 0;
      min-height: 100vh;
      background: $white;

      .content {
        margin: 0;

        &.rounded {
          border-radius: 0;
        }
      }
    }
  }
}
