@import '../variables';

.select2-container {
  .selection {
    .select2-selection {
      border-color: $main-border-color;
      border-radius: $border-radius;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .select2-dropdown {
    border-color: $main-border-color;
    border-radius: 0 0 $border-radius $border-radius;

    &.select2-dropdown--above {
      border-radius: $border-radius $border-radius 0 0;
    }

    .select2-results {
      .select2-results__options {
        padding: 10px;
        font-size: 14px;

        .select2-results__option {
          margin-bottom: 0;
          padding: 2px;
          border-radius: $border-radius;
          color: $main-font-color;

          &:last-child {
            margin-bottom: 0;
          }

          &.select2-results__option--highlighted {
            background-color: $main-color;
          }

          &.select2-results__option--disabled {
            color: $main-font-color-disabled;

            &:hover {
              cursor: not-allowed;
            }
          }

          .select__item {
            .select__item-image-container {
              position: relative;
              top: 2px;
              padding-right: 5px;

              .select_item-image {
                height: 18px;
                border: 1px solid $main-border-color;
              }
            }

            .select__item-images-container {
              position: relative;
              top: 2px;
              padding-right: 5px;

              .select_item-image {
                height: 18px;
                border: 1px solid $main-border-color;
                display: inline-block;
                margin-right: 5px;

                &:last-child {
                  margin-right: unset;
                }
              }
            }

            .select_item-text {
              position: relative;
              top: -5px;
              font-size: $input-font-size;
            }
          }
        }
      }
    }
  }

  &.select2-container--single {
    .selection {
      .select2-selection {
        .select2-selection__rendered {
          .select__item {
            .select__item-image-container {
              position: relative;
              top: 5px;
              padding-right: 5px;

              .select_item-image {
                height: 18px;
                border: 1px solid $main-border-color;
              }
            }

            .select__item-images-container {
              position: relative;
              top: 2px;
              padding-right: 5px;

              .select_item-image {
                height: 18px;
                border: 1px solid $main-border-color;
                display: inline-block;
                margin-right: 5px;

                &:last-child {
                  margin-right: unset;
                }
              }
            }
          }
        }
      }
    }

    &.select2-container--has-clear-btn {
      .selection {
        .select2-selection {
          .select2-selection__rendered {
            padding-right: calc($input-height + ($input-height / 2));
          }
        }
      }
    }

    .selection {
      .select2-selection {
        height: $input-height;

        .select2-selection__rendered {
          line-height: $input-height;
          font-size: $input-font-size;
          color: $main-font-color;
          padding-right: $input-height;
        }

        .select2-selection__clear {
          color: $white;
          position: absolute;
          top: calc(($input-height / 2) - ($input-height / 4));
          right: calc($input-height - ($input-height / 4));
          margin-right: 0;
          border-radius: 50%;
          background-color: $danger-color;
          height: calc($input-height / 2);
          width: calc($input-height / 2);
          z-index: 1;

          &:hover {
            background-color: $danger-color-hover;
          }

          span {
            position: absolute;
            line-height: 1.4;
            text-align: center;
            left: 0;
            top: 0;
            width: 100%;
            font-size: 16px;
            height: 100%;
          }
        }

        .select2-selection__arrow {
          height: $input-height;
          padding-right: 14px;

          b[role=presentation] {
            border-color: $main-color transparent transparent transparent;
            border-width: 8px 6px 0 6px;
            margin-top: -4px;

            &:hover {
              border-color: $main-color-hover transparent transparent transparent;
            }
          }
        }
      }
    }

    &.select2-container--open {
      .selection {
        .select2-selection {
          .select2-selection__arrow {
            b[role=presentation] {
              border-color: transparent transparent $main-color transparent;
              border-width: 0 6px 8px 6px;

              &:hover {
                border-color: transparent transparent $main-color-hover transparent;
              }
            }
          }
        }
      }
    }

    .select2-dropdown {
      .select2-search {
        padding: 10px 10px 0;

        .select2-search__field {
          border-radius: $border-radius;
          border-color: $main-border-color;
          color: $main-font-color;
          height: $input-height;
          font-size: $input-font-size;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }
  }

  &.select2-container--multiple {
    .selection {
      .select2-selection {
        padding-right: 6px;
        padding-bottom: 6px;

        .select2-selection__rendered {
          .select2-selection__choice {
            border-radius: $border-radius;
            border-color: $main-border-color;
            background-color: transparent;
            margin-left: 6px;
            margin-top: 6px;
            height: calc($input-height - 12px - 2px);

            .select2-selection__choice__display {
              padding-left: 5px;
              line-height: 23px;

              .select__item {
                .select__item-image-container {
                  position: relative;
                  top: 4px;
                  padding-right: 5px;

                  .select_item-image {
                    height: 15px;
                    border: 1px solid $main-border-color;
                  }
                }

                .select__item-images-container {
                  position: relative;
                  top: 2px;
                  padding-right: 5px;

                  .select_item-image {
                    height: 18px;
                    border: 1px solid $main-border-color;
                    display: inline-block;
                    margin-right: 5px;

                    &:last-child {
                      margin-right: unset;
                    }
                  }
                }

                .select_item-text {
                  position: relative;
                  top: -1px;
                }
              }
            }

            .select2-selection__choice__remove {
              border-radius: 50%;
              background-color: $danger-color;
              color: $white;
              top: 4px;
              left: 3px;
              font-size: 15px;
            }
          }
        }

        .select2-search--inline {
          position: relative;
          top: -3px;

          .select2-search__field {
            color: $main-font-color;
          }
        }
      }
    }

    &.select2-container--is-empty {
      .selection {
        .select2-selection {
          height: $input-height;

          .select2-search {
            top: 3px;

            .select2-search__field {
              font-size: $input-font-size;
              height: auto;
            }
          }
        }
      }
    }
  }

  &--disabled {

    .select2-selection--multiple {
      background-color: $disabledInputBg !important;

      .select2-selection__choice {
        padding-left: 0;
      }
    }

    .select2-selection--single {
      background-color: $disabledInputBg !important;
    }
  }
}

.wider-select.select2-container--open {

  .select2-selection {
    //border-bottom: none;
  }

  .select2-dropdown--below,
  .select2-dropdown--above {
    width: 550px !important;
    max-width: 100vh;
  }

  .select2-dropdown--below {
    margin-top: -1px;
    border-top: 1px solid $main-border-color;
  }

  .select2-dropdown--above {
    transform: translateY(1px);
    border-bottom: 1px solid $main-border-color;
  }
}

@media only screen and (max-width: 950px) {
  .wider-select.select2-container--open {

    .select2-dropdown--below,
    .select2-dropdown--above {
      width: calc(100vw - 386px) !important;
    }
  }

  .select2-container {
    .select2-dropdown {
      .select2-results {
        .select2-results__options {
          .select2-results__option {
            white-space: initial;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .wider-select.select2-container--open {

    .select2-dropdown--below,
    .select2-dropdown--above {
      width: calc(100vw - 2 * $gridColumnGap) !important;
    }
  }

  .select2-container {

    &.select2-container--single {

      .select2-dropdown {
        .select2-search {
          .select2-search__field {
            height: $xsInputHeight;
          }
        }
      }

      .selection {

        .select2-selection {
          height: 30px;

          .select2-selection__rendered {
            line-height: 30px;
            font-size: 12px;
          }

          .select2-selection__clear {
            top: 4px;
          }

          .select2-selection__arrow {
            height: 30px;
          }
        }
      }
    }

    .select2-dropdown {
      border-color: $main-border-color;
      border-radius: 0 0 $border-radius $border-radius;

      &.select2-dropdown--above {
        border-radius: $border-radius $border-radius 0 0;
      }

      .select2-results {
        .select2-results__options {
          font-size: 12px;

          .select2-results__option {

            .select__item {
              .select__item-image-container {

                .select_item-image {
                  height: 14px;
                }
              }

              .select__item-images-container {

                .select_item-image {
                  height: 14px;
                }
              }

              .select_item-text {
                font-size: 12px;
                top: -2px;
              }
            }
          }
        }
      }
    }

    &.select2-container--multiple {
      &.select2-container--is-empty {
        .selection {
          .select2-selection {
            height: 30px;
          }
        }
      }
    }
  }
}
