@import "../variables";

.dropdown {

  &__menu {
    display: none;
    padding: 14px 10px;
    border: 1px solid $black;
    border-radius: 5px;
    background: $white;
  }

  &__menu-item {
    text-decoration: none;
    color: $main-font-color;
    display: block;
    padding: 6px 16px;

    i {
      font-size: 22px;
      padding-right: 8px;
      width: 30px;
      box-sizing: border-box;
    }

    &:hover {
      color: $main-font-color-hover;
    }
  }

  &__opener {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    padding: 10px;

    i {
      cursor: pointer;
      font-size: 20px;
      transition: transform .2s ease-in;
    }

    &:hover {
      i {
        transform: scale(1.1);
      }
    }

    &--btn-danger {
      color: $danger-color;
    }
  }

  &--is-opened {
    .dropdown {

      &__menu {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .dropdown {

    &__opener {
      margin-bottom: 0;
      padding: 2px;
    }

    &__menu {
      padding: 6px 4px;
    }

    &__menu-item {
      padding: 4px;
    }
  }
}

