.application-metadata-identity-check-reports {
  border: 1px solid $main-border-color;
  border-radius: $border-radius;
  padding: 10px;

  .application-metadata-identity-check-reports__title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .application-metadata-identity-check-reports__list {

    .application-metadata-identity-check-reports__list-item {
      .application-metadata-identity-check-reports__list-item-link {
      }
    }
  }
}
