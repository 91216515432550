.text-danger {
  color: $danger-color;
}

.text-success {
  color: $main-color;
}

.text-warning {
  color: $serious-warning-color;
}

.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-font-size-75px {
  font-size: 75px;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
