@import '../variables';

.questionnaire-version-2 {
  .form__compound-element {
    padding-top: 1rem;

    > label {
      font-weight: bold;
      line-height: 1.1;
      display: block;
      padding-bottom: .5rem;
    }
  }

  .false-label {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.1;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }

  .flex-radio {

    .form__radio-element {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      padding-bottom: 4px;
    }
  }
}
