.application-persons-company-owners-documents {
  border: 1px solid $main-border-color;
  border-radius: $border-radius;
  padding: 10px;

  .application-persons-company-owners-documents__title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .application-persons-company-owners-documents__list {
    .application-persons-company-owners-documents__list-item {
      .application-persons-company-owners-documents__list-item-name {
      }

      .application-persons-company-owners-documents__list-item-link {
      }
    }
  }
}
