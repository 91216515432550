.form-help-button {
  all: unset;

  &:after {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    content: '\f05a';
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.form-help-content {
  background: #666;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  max-width: min(625px, 100%);
  min-width: min(100%, 625px);
  padding: 10px;
}
