@import '../variables';

.side-nav {
  width: calc($sideNavWidth - 1px);
  border-right: 1px solid $navyGrey;
  background: $white;
  min-height: 100vh;
  transition: width .1s ease-in;
  position: fixed;
  left: 0;
  z-index: 100;

  &__bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 40px;

    i {
      cursor: pointer;
      color: $black;
      transition: color .2s ease-in;

      &:hover {
        color: $main-color;
      }
    }
  }

  .locale-switcher {

    &__link {
      font-size: $input-font-size;

      &:hover,
      &--active {
        color: $main-color;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 40px;
    box-sizing: border-box;
  }

  &__logo {
    padding: 7px 20px;

    img {
      width: 100%;
    }
  }

  &__item {
    width: 100%;
  }

  &__link {
    padding: 7px 20px;
    text-decoration: none;
    transition: color .2s ease-in;
    color: $main-font-color;

    span {
      font-size: $input-font-size;
      transition: font-size .1s ease-in;
    }

    i {
      transition-delay: .2s;
      transition: font-size .1s ease-in;
      min-width: 20px;
    }

    &--active,
    &:hover {
      color: $main-color;
    }
  }
}


.admin-layout--side-nav-collapsed {
  padding-left: $sideNavCollapsedWidth;

  .side-nav {
    width: calc($sideNavCollapsedWidth - 1px);

    &__logo {
      padding: 0 4px;
      margin-bottom: 40px;
    }

    &__link {
      padding: 7px;
      display: flex;
      justify-content: center;

      span {
        font-size: 0;
      }

      i {
        font-size: 22px;
      }
    }

    &__bottom {
      flex-direction: column;
      align-items: center;

      i {
        padding: 10px 0;
        transform: rotate(180deg);
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .side-nav {

    &__bottom {
      padding: 0 20px 60px;
    }
  }
}
