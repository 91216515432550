@import "../variables";

.documentation {

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title  {
    font-weight: 700;
  }

  &__collapse-btn {
    border: none;
    background: initial;
    color: $main-color;
    padding-left: 20px;
    cursor: pointer;

    i {
      font-size: 20px;
    }
  }

  &__files {
    padding: 20px 0 0 40px;
    border-bottom: 1px solid $main-border-color;
  }

  &__file {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $navyGrey;
    padding: 2px 6px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__file-controls {
    display: flex;
  }

  &__file-size {
    padding-right: 20px;
  }
}

@media only screen and (max-width: $sm) {

  .documentation {

    &__header {
      flex-wrap: wrap;
    }
  }
}
