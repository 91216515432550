@import '../variables';

.file-upload {

  input.file-upload__item-client-name {
    border: none;
    text-decoration: underline;
    width: calc(100% - 120px);
    padding-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $input-font-size;
    color: $main-font-color;
    font-family: 'Poppins', sans-serif;

    &:focus-visible {
      outline: none;
    }
  }
}

.file-upload__drag-and-drop-container {
  border: 3px dashed $main-border-color;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: border-color .2s ease-in, color .2s ease-in;
}

.file-upload__drag-and-drop-container:hover {
  border-color: $main-color;
  cursor: pointer;
  color: $main-color;
}

.file-upload__drag-and-drop-container--is-dragover {
  border-color: $main-color;
  color: $main-color;
}

.file-upload__drag-and-drop-container--is-disabled {
  cursor: not-allowed!important;
  border-color: $main-border-color!important;
  color: unset!important;
}

.file-upload__drag-and-drop-content {
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.file-upload__drag-and-drop-icon {
  font-size: 60px;
  margin-bottom: 10px;
  text-align: center;
}

.file-upload__drag-and-drop-text {
  text-align: center;
}

.file-upload__item-uploading-container, .file-upload__item-container {
  margin-bottom: 10px;

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.file-upload__item-uploading-progress-bar {
  background-color: $main-border-color;
  border-radius: $border-radius;
}

.file-upload__item-uploading-progress-bar-value {
  background-color: $main-color;
  width: 0;
  border-radius: $border-radius;
  height: 30px;
  color: $white;
  text-align: center;
  line-height: 30px;
}

.file-upload {

  &__item-container {
    display: flex;

    button {
      margin-left: 10px;
    }
  }

  &__item-open-file {
    i {
      background: $main-color;

      &:hover {
        background: $main-color-hover;
      }
    }
  }

  &__item-download-file {
    i {
      background: $main-font-color;

      &:hover {
        background: $main-font-color-hover;
      }
    }
  }

  &__item-remove-file {
    i {
      background: $danger-color;

      &:hover {
        background: $danger-color-hover;
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .file-upload {

    &__item-container {
      flex-wrap: wrap;

      input {
        width: 100%;
      }

      button {
        margin-top: 10px;

         &:first-of-type {
           margin-left: 0;
         }
      }
    }

    &__item-client-name {
      @include input-styles-xs;
      height: $xsInputHeight;
      max-height: $xsInputHeight;
    }
  }
}
