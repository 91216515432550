@import "../variables";

.input-group {
  display: flex;

  &__input {
    width: 100%;

    input {
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }


  &__addon-btn {
    box-sizing: border-box;
    height: $input-height;
    outline: none;
    border-radius: 0  $border-radius $border-radius 0;
    border-left: 0;
    min-width: 39px;
    text-align: center;
    transition: background-color 0.2s ease-in, border 0.2s ease-in;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &:hover {
      cursor: pointer;
    }

    &--primary {
      border: 1px solid $main-color;
      background-color: $main-color;
      color: $white;

      &:hover {
        background-color: $main-color-hover;
        border-color: $main-color-hover;
      }
    }

    &--secondary {
      border: 1px solid $main-font-color;
      background-color: $main-font-color;
      color: $white;

      &:hover {
        background-color: $main-font-color-hover;
        border-color: $main-font-color-hover;
      }
    }
  }
}

@media only screen and (max-width: $xs) {

  .input-group {

    &__addon-btn {
      height: $xsInputHeight;
    }
  }
}
