.application-persons-authorised-persons-to-act-for-company-power-fo-attorney-documents {
  border: 1px solid $main-border-color;
  border-radius: $border-radius;
  padding: 10px;


  .application-persons-authorised-persons-to-act-for-company-power-fo-attorney-documents__title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .application-persons-authorised-persons-to-act-for-company-power-fo-attorney-documents__btn {
  }
}
